import { Loader } from 'src/components/Loader';

const MarketBalance = ({
  activeTab,
  balance,
  pairFromPath,
  loading,
  firstCurrency,
  secondCurrency,
}) => {
  const isBuy = activeTab === 'buy';

  console.log(pairFromPath);

  return (
    <div className="avbl">
      {loading ? (
        <Loader size={30} />
      ) : (
        `Available ${balance} ${isBuy ? secondCurrency.toUpperCase() : firstCurrency.toUpperCase()}`
      )}
    </div>
  );
};

export default MarketBalance;
