import PUBLIC_URL from 'src/configs/PUBLIC_URL';
import request from 'src/request';
import { setAppAlert } from 'src/store/slice/appAlert';

export const fetchTickersByCurrency = ({
  firstCurrency,
  secondCurrency,
  setLastPrice,
  setLoadingTickers,
  setLoadingTransactions,
  dispatch,
  navigate,
}) => {
  request
    .get(`${PUBLIC_URL}/v1/tickers/${firstCurrency.toUpperCase()}-${secondCurrency.toUpperCase()}`)
    .then((response) => {
      const data = response.data;
      setLastPrice(data.ticker.to);
    })
    .catch((response) => {
      dispatch(
        setAppAlert({
          message: response?.data?.error || 'Something went wrong.',
          isSuccess: false,
        }),
      );
      navigate('/');
    })
    .finally(() => {
      setLoadingTickers(false);
      setLoadingTransactions(false);
    });
};

export const fetchMarketTradesByCurrency = ({
  firstCurrency,
  secondCurrency,
  setMarketTrade,
  setLoadingTickers,
  setLoadingTransactions,
  dispatch,
}) => {
  request
    .get(
      `${PUBLIC_URL}/v1/market-trades/${
        firstCurrency.toLowerCase() + secondCurrency.toLowerCase()
      }`,
    )
    .then((response) => {
      const data = response.data;
      setMarketTrade(data.splice(0, 15));
    })
    .catch(({ response }) => {
      dispatch(
        setAppAlert({
          message: response?.data?.error || 'Something went wrong.',
          isSuccess: false,
        }),
      );
    })
    .finally(() => {
      setLoadingTickers(false);
      setLoadingTransactions(false);
    });
};
