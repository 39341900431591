import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useDispatch } from 'src/store/useDispatch';

import { RootState } from 'src/store/store';
import { fetchNotificationsCount } from 'src/store/slice/notificationsCount';
import { fetchUser } from 'src/store/slice/user';
import { fetchTickers } from 'src/store/slice/tickers';
import { fetchCurrencies } from 'src/store/slice/currencies';
import { ITradeData } from 'src/interfaces';

import { Container } from 'src/components/Container';
import { Loader } from 'src/components/Loader';
import TradeStatus from './components/TradeStatus';
import MyTrade from './components/MyTrade';
import Chart from './components/Chart';
import Spot from './components/Spot';
import OrdersTabs from './components/OrderTabs';
import MobileChart from './components/MobileChart';
import CurrenciesSlider from 'src/components/CurrenciesSlider';

import * as process from 'process';
import { fetchMarketTradesByCurrency, fetchTickersByCurrency } from './helpers';

import './index.css';

const Trade = () => {
  const [kycStatus, setKycStatus] = useState('');
  const [activeButton, setActiveButton] = useState('buy');
  const [ordersCount, setOrdersCount] = useState(0);
  const [offers, setOffers] = useState([]);
  const [bids, setBids] = useState([]);
  const [lastPrice, setLastPrice] = useState('0');
  const [updatedPrice, setUpdatedPrice] = useState('');
  const [marketTradeData, setMarketTrade] = useState<ITradeData[]>([]);
  const [newMarketData, setNewMarketData] = useState<ITradeData | undefined>();
  const [loadingTickers, setLoadingTickers] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);

  const { pair = 'btc-usdt' } = useParams();
  const token = localStorage.getItem('access');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);
  const { isDark } = useSelector(({ isDark }) => isDark);
  const { tickers } = useSelector((state: RootState) => state.tickers);
  const { currencies } = useSelector((state: RootState) => state.currencies);

  const firstCurrency = pair.split('-')[0];
  const secondCurrency = pair.split('-')[1];

  useEffect(() => {
    if (token) {
      dispatch(fetchNotificationsCount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (pair) {
      const orderBookSocket = new WebSocket(`${process.env.REACT_APP_KRAKEN_WEBSOCKET_URL}`);
      const tradesSocket = new WebSocket(`${process.env.REACT_APP_KRAKEN_WEBSOCKET_URL}`);

      setLoadingTickers(true);
      setLoadingTransactions(true);

      fetchMarketTradesByCurrency({
        firstCurrency,
        secondCurrency,
        setMarketTrade,
        setLoadingTickers,
        setLoadingTransactions,
        dispatch,
      });

      fetchTickersByCurrency({
        firstCurrency,
        secondCurrency,
        setLastPrice,
        setLoadingTickers,
        setLoadingTransactions,
        dispatch,
        navigate,
      });

      const krakenPair = `${firstCurrency}/${secondCurrency}`.toUpperCase();

      const connectSocketOrderBook = () => {
        orderBookSocket.addEventListener('open', () => {
          const message = {
            event: 'subscribe',
            pair: [krakenPair],
            subscription: { name: 'book', depth: 10 },
          };
          orderBookSocket.send(JSON.stringify(message));
        });

        orderBookSocket.addEventListener('message', (event) => {
          const newData = JSON.parse(event.data);

          if (Array.isArray(newData)) {
            const [, orderBookUpdate] = newData;
            if (orderBookUpdate?.as) setOffers(orderBookUpdate.as);
            if (orderBookUpdate?.bs) setBids(orderBookUpdate.bs);
          }
        });
      };

      const connectSocketLiveTrades = () => {
        tradesSocket.addEventListener('open', () => {
          const message = {
            event: 'subscribe',
            pair: [krakenPair],
            subscription: { name: 'trade' },
          };
          tradesSocket.send(JSON.stringify(message));
        });

        tradesSocket.addEventListener('message', (event) => {
          const newData = JSON.parse(event.data);
          console.log({ newData });
          if (Array.isArray(newData)) {
            const [, tradeUpdate] = newData;
            if (tradeUpdate) {
              setNewMarketData(tradeUpdate);
            }
          }
        });
      };

      connectSocketLiveTrades();
      connectSocketOrderBook();

      return () => {
        orderBookSocket.close();
        tradesSocket.close();
      };
    }
  }, [pair]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (newMarketData) {
      const temp = marketTradeData;
      console.log(temp);
      temp.unshift(newMarketData);
      setMarketTrade(temp.filter((t) => t.amount).slice(0, 15));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMarketData]);

  useEffect(() => {
    const isUserFetched = Object.keys(user).length;
    const token = localStorage.getItem('access');

    if (isUserFetched) {
      setKycStatus(user.kyc_status.toUpperCase());
      return;
    }

    if (!isUserFetched && token) {
      dispatch(fetchUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!tickers.length) {
      dispatch(fetchTickers());
    }

    if (!currencies.length) {
      dispatch(fetchCurrencies());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickers, currencies]);

  useEffect(() => {
    if (lastPrice !== '0') {
      const tradeSocket = new WebSocket(`${process.env.REACT_APP_KRAKEN_WEBSOCKET_URL}`);

      const connectSocket = () => {
        tradeSocket.addEventListener('open', () => {
          const message = {
            event: 'subscribe',
            pair: [`${firstCurrency}/${secondCurrency}`.toUpperCase()],
            subscription: { name: 'trade' },
          };
          tradeSocket.send(JSON.stringify(message));
        });

        tradeSocket.addEventListener('message', (event) => {
          const newData = JSON.parse(event.data);

          if (Array.isArray(newData)) {
            const [, tradeUpdate] = newData;
            if (tradeUpdate?.[0]) {
              setUpdatedPrice(tradeUpdate[0][0]);
            }
          }
        });
      };

      connectSocket();

      return () => {
        tradeSocket.close();
      };
    }
  }, [firstCurrency, lastPrice, pair, secondCurrency]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loadingTickers || loadingTransactions) {
    return <Loader overlay />;
  }

  return (
    <>
      <div className="d-flex">
        <div className="col-4 d-lg-flex d-none flex-column">
          <TradeStatus
            offers={offers}
            bids={bids}
            lastPrice={lastPrice}
            updatedPrice={updatedPrice}
            firstCurrency={firstCurrency}
            secondCurrency={secondCurrency}
          />
          <MyTrade
            myTradeData={marketTradeData}
            firstCurrency={firstCurrency}
            secondCurrency={secondCurrency}
          />
        </div>
        <div className="col-lg-8 col-12 d-flex flex-column">
          <Chart symbol={pair} />
          <div className="h-100 trade-chart mobile-trade-chart ms-lg-3 ms-0 mb-3">
            <ul className="nav nav-pills trade-spot-tab trade-chart-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="pill"
                  href="#chart"
                  style={{ color: isDark ? '#fff' : '#172a4f' }}
                >
                  Chart
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="pill"
                  href="#order-book"
                  style={{ color: isDark ? '#fff' : '#172a4f' }}
                >
                  Order Book
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="pill"
                  href="#trades"
                  style={{ color: isDark ? '#fff' : '#172a4f' }}
                >
                  Trades
                </a>
              </li>
            </ul>
            <div className="tab-content h-100">
              <div id="chart" className="container tab-pane active h-100 px-0 pb-5">
                <MobileChart symbol={pair} />
              </div>
              <div id="order-book" className="container tab-pane fade">
                <MyTrade myTradeData={marketTradeData} />
              </div>
              <div id="trades" className="container tab-pane fade">
                <TradeStatus
                  offers={offers}
                  bids={bids}
                  lastPrice={lastPrice}
                  updatedPrice={updatedPrice}
                />
              </div>
            </div>
          </div>
          <div className="d-lg-none d-flex justify-content-between mt-3 trade-status-mobile mb-3">
            <div className="col-6 h-100">
              <TradeStatus
                offers={offers}
                bids={bids}
                lastPrice={lastPrice}
                updatedPrice={updatedPrice}
              />
            </div>
            <div className="col-6 ms-2 me-2 h-100">
              <MyTrade myTradeData={marketTradeData} />
            </div>
          </div>
          <Spot
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            setOrdersCount={setOrdersCount}
            kycStatus={kycStatus}
            firstCurrency={firstCurrency}
            secondCurrency={secondCurrency}
          />
        </div>
      </div>
      <OrdersTabs ordersCount={ordersCount} key={ordersCount} kycStatus={kycStatus} />
      <Container margin="3rem 0 -3rem">
        <CurrenciesSlider currencies={currencies} tickers={tickers} />
      </Container>
    </>
  );
};

export default Trade;
